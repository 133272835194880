import React, { useState, useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { DOMComponents } from "next/delegates"
import * as route from "../route"

export interface ModalProps {
  title: string | undefined
  isOpen?: boolean
  onClose: () => void
  size?: string
  content?: string
  children?: React.ReactNode
}

export function Modal({ title, children, content, onClose, isOpen = false, size = "50%" }: ModalProps) {
  const modalContentRef = useRef<HTMLDivElement>(null)

  const keyboardHandler = (event: KeyboardEvent) => {
    if (event.key === "Escape") onClose()
  }

  useEffect(() => {
    if (isOpen && modalContentRef.current) disableBodyScroll(modalContentRef.current)
    document.addEventListener("keyup", keyboardHandler)

    return () => {
      if (isOpen && modalContentRef.current) enableBodyScroll(modalContentRef.current)
      document.removeEventListener("keyup", keyboardHandler)
    }
  }, [isOpen])

  useEffect(() => {
    if (!modalContentRef.current || !isOpen) return

    const element = modalContentRef.current.querySelector(".modal-content-inner")
    if (element) DOMComponents.initialize(element)
  }, [modalContentRef.current, isOpen, content])

  return createPortal(
    <div ref={modalContentRef} className={`modal ${isOpen ? "open" : ""}`}>
      <div className="overlay" onClick={onClose} />

      <div className="modal-inner">
        <div className="modal-content" style={size ? { width: size } : undefined}>
          {title != null && (
            <div className="modal-title">
              <h4>{title}</h4>

              <button type="button" style={{ padding: 0 }} onClick={onClose}>
                &times;
              </button>
            </div>
          )}

          {content ? (
            <div className="modal-content-inner" dangerouslySetInnerHTML={{ __html: content }} />
          ) : (
            <div className="modal-content-inner">{children}</div>
          )}
        </div>
      </div>
    </div>,
    document.body
  )
}

type useModalOpenResult = [boolean, () => any, () => any]
export function useModalOpen(initial = false): useModalOpenResult {
  const [isOpen, open] = useState(initial)
  return [isOpen, () => open(true), () => open(false)]
}

type LinkModalProps = ModalProps & { href: string; closeOnRedirect?: boolean }
export function LinkModal({ href, closeOnRedirect = false, onClose, ...rest }: LinkModalProps) {
  const [content, setContent] = useState("...")

  useEffect(() => {
    if (closeOnRedirect) route.preventVisits(true)

    fetch(href)
      .then(response => response.text())
      .then(data => setContent(data))
  }, [href, closeOnRedirect])

  useEffect(() => {
    const formSubmitHandler = (event: Event) => {
      const xhr: XMLHttpRequest = (event as CustomEvent).detail[0]
      const contentType = xhr.getResponseHeader("Content-Type")
      const isRedirect = contentType && contentType.includes("text/javascript")

      if (isRedirect && closeOnRedirect) onClose()
      if (!isRedirect) setContent(xhr.responseText)
    }

    document.addEventListener("ajax:complete", formSubmitHandler)
    return () => document.removeEventListener("ajax:complete", formSubmitHandler)
  })

  return <OpenModal content={content} onClose={onClose} {...rest} />
}

export function OpenModal({ onClose, ...rest }: ModalProps) {
  const [isOpen, open] = useState(true)

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        open(false)
        onClose()
      }}
      {...rest}
    />
  )
}
