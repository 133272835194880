import React from "react"

export function Fieldset({
  field,
  error,
  children,
  className = ""
}: {
  field?: string
  error?: string
  className?: string
  children: React.ReactNode
}) {
  return (
    <div className={`fieldset ${className}`}>
      <div className="fieldset-title">
        {field && <h4>{field}</h4>}
        {error && <p className="text-danger">{error}</p>}
      </div>
      <div className="fieldset-content">{children}</div>
    </div>
  )
}
