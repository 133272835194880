import React, { useEffect, useState } from "react"
import Turbolinks from "turbolinks"

const DARK_MODE_ID = "admin-dark-mode"
const DARK_MODE_STORAGE_KEY = "bh-admin-dark-mode"

export function DarkMode() {
  const [enabled, setEnabled] = useState(localStorage[DARK_MODE_STORAGE_KEY])

  useEffect(() => {
    if (enabled) {
      if (document.getElementById(DARK_MODE_ID)) return

      const style = document.createElement("style")
      style.id = DARK_MODE_ID
      style.innerText = `
        html { background-color: white; }
        body { filter: invert(90%); min-height: 100vh; }
      `

      document.head.appendChild(style)
      localStorage.setItem(DARK_MODE_STORAGE_KEY, "true")
    } else {
      document.getElementById(DARK_MODE_ID)?.remove()
      localStorage.removeItem(DARK_MODE_STORAGE_KEY)
      Turbolinks?.clearCache()
    }
  }, [enabled])

  return (
    <button
      type="button"
      className="nav-link btn btn-outline-secondary btn-sm ps-2 pe-2"
      onClick={() => setEnabled(!enabled)}
    >
      <i className={`fa ${enabled ? "fa-sun-o" : "fa-moon-o"}`} />
    </button>
  )
}
