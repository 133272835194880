export function get<T = any>(object: object, path: string): T {
  return path.split(".").reduce((inner: any, key: string) => inner[key], object)
}

export function groupBy<T>(iterable: T[], property: keyof T) {
  return iterable.reduce((grouped: any, value: any) => {
    const group = value[property]

    if (grouped[group]) {
      grouped[group].push(value)
    } else {
      grouped[group] = [value]
    }

    return grouped
  }, {})
}

export function mapByGroup<T>(
  iterable: T[],
  property: keyof T,
  callback: (value: [string, T[]], index: number, array: [string, T[]][]) => any
) {
  return Object.entries<T[]>(groupBy(iterable, property))
    .sort()
    .map(callback)
}

export default {
  get,
  groupBy,
  mapByGroup
}
