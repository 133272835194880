import React, { useState } from "react"
import { ImageArchitecture, Provider, Size } from "../../graph"
import { formatSize } from "../../models"

type SizeCategory = {
  id: string
  name: string
  description: string
  architecture: ImageArchitecture
  subcategories: {
    id: string
    name: string
    description: string
    architecture: ImageArchitecture
    sizes: Size[]
  }[]
}

type Price = Provider & { sizeCategories: SizeCategory[] }

export function ProviderPrices({ prices }: { prices: Price[] }) {
  const [providerIndex, setProviderIndex] = useState(0)
  const [categoryIndex, setCategoryIndex] = useState(0)
  const [subcategoryIndex, setSubcategoryIndex] = useState(0)

  const categories = prices[providerIndex].sizeCategories
  const subcategories = categories[categoryIndex]?.subcategories ?? []
  const subcategory = subcategories[subcategoryIndex]

  return (
    <div className="providers-pricing">
      <div className="providers-select segments-alt spacing-bottom-sm">
        {prices.map((provider, index) => (
          <a
            key={provider.id}
            href="#"
            onClick={event => {
              event.preventDefault()
              setProviderIndex(index)
              setCategoryIndex(0)
              setSubcategoryIndex(0)
            }}
            className={providerIndex === index ? "active" : ""}
          >
            <img src={provider.logoUrl} alt="Logo" />
          </a>
        ))}
      </div>

      <div className="size-categories">
        <div className="category-select spacing-bottom-sm">
          {categories.map((category, index) => (
            <div key={category.id} className={`category ${index === categoryIndex ? "active" : ""}`}>
              <button
                type="button"
                onClick={() => {
                  setCategoryIndex(index)
                  setSubcategoryIndex(0)
                }}
              >
                {category.name}
              </button>
              <p>{category.description}</p>
            </div>
          ))}
        </div>

        <div className="tabs-wrapper spacing-bottom-sm">
          <nav className="tabs">
            {subcategories.map((subcategory, index) => (
              <a
                key={subcategory.id}
                href="#"
                className={index === subcategoryIndex ? "active" : ""}
                onClick={event => {
                  event.preventDefault()
                  setSubcategoryIndex(index)
                }}
              >
                {subcategory.name}
              </a>
            ))}
          </nav>
        </div>

        {subcategory && (
          <div className="list-select readonly">
            {subcategory.sizes.map(size => (
              <div key={size.id} className="item">
                {format(size)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const format = (size: Size) => (
  <>
    <div>{`${formatSize(size, "cost")}/mo (${formatSize(size, "costPerHour")}/ho)`}</div>
    <div className="chips">
      <span className="chip">
        <span className="chip-label">RAM</span>
        <span className="chip-value">{formatSize(size, "memory")}</span>
      </span>
      <span className="chip">
        <span className="chip-label">CPU</span>
        <span className="chip-value">{formatSize(size, "processor")}</span>
      </span>
      <span className="chip">
        <span className="chip-label">DISK</span>
        <span className="chip-value">{formatSize(size, "storage")}</span>
      </span>
      <span className="chip">
        <span className="chip-label">TRAFFIC</span>
        <span className="chip-value">{formatSize(size, "bandwidth")}</span>
      </span>
    </div>
  </>
)
