import React, { useEffect, useState } from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { useOneOfMany } from "next/hooks/selection"
import _ from "next/utils/_"
import { Tabs, Segments, Loading } from ".."
import { IMAGE_FRAGMENT, GetImages, GetImagesVariables, Image, ImageKind, ImageArchitecture } from "../../graph"

const GET_IMAGES = gql`
  query GetImages($providerId: ID!, $type: ImageKind, $architecture: ImageArchitecture) {
    images(providerId: $providerId, type: $type, architecture: $architecture) {
      ...Image
    }
  }

  ${IMAGE_FRAGMENT}
`

export function ImagesSelect({
  providerId,
  imageId = "",
  className,
  onChange,
  kind = ImageKind.STANDARD,
  architecture = ImageArchitecture.X86,
  theme = "segments",
  choices = [ImageKind.STANDARD, ImageKind.SNAPSHOT, ImageKind.CUSTOM]
}: {
  providerId: string
  imageId?: string
  onChange: (id: string) => void
  className?: string
  kind?: ImageKind
  architecture?: ImageArchitecture
  theme?: "tabs" | "segments"
  choices?: ImageKind[]
}) {
  const [type, setType] = useState<ImageKind>(kind)
  const { data, loading, error, refetch } = useQuery<GetImages, GetImagesVariables>(GET_IMAGES, {
    variables: { providerId, type, architecture }
  })

  if (error) throw error

  return (
    <div className="images-select">
      <div className="spacing-bottom-sm">
        {theme === "tabs" && (
          <Tabs
            items={choices}
            active={type}
            format={formatImageKind}
            onChange={item => {
              setType(item as ImageKind)
              onChange("")
              refetch()
            }}
          />
        )}
        {theme === "segments" && (
          <Segments
            items={choices}
            active={type}
            format={formatImageKind}
            onChange={item => {
              setType(item as ImageKind)
              onChange("")
              refetch()
            }}
          />
        )}
      </div>

      {loading ? (
        <Loading />
      ) : (
        <DistributionSelect active={imageId} className={className} value={data?.images ?? []} onChange={onChange} />
      )}
    </div>
  )
}

function formatImageKind(type: ImageKind): string {
  switch (type) {
    case ImageKind.STANDARD:
      return "Distribution"
    case ImageKind.SNAPSHOT:
      return "Snapshot"
    case ImageKind.CUSTOM:
      return "Other"
    default:
      return ""
  }
}

function DistributionSelect({
  active: selected,
  value,
  onChange,
  className = ""
}: {
  active: string
  value: Image[]
  className?: string
  onChange: (id: string) => void
}) {
  const [active, activate] = useOneOfMany()

  useEffect(() => {
    const image = value.find(img => img.id === selected)
    if (image) activate(image.distribution)
  }, [selected])

  return (
    <div className={`distribution-select ${className}`}>
      {_.mapByGroup<Image>(value, "distribution", ([distro, images]) => (
        <div key={distro}>
          <AutomaticSelect
            name={distro}
            initial={active[distro] && selected}
            active={Boolean(active[distro])}
            onClick={id => {
              activate(distro)
              onChange(id)
            }}
            onChange={onChange}
            blank="Select version..."
            options={images}
          />
        </div>
      ))}
    </div>
  )
}

function AutomaticSelect({
  name,
  active,
  initial = "",
  onChange,
  onClick,
  options,
  blank
}: {
  name: string
  active: boolean
  initial?: string
  onChange: (id: string) => void
  onClick: (id: string) => void
  options: { id: string; name: string }[]
  blank?: string
}) {
  const [selected, setSelected] = useState(initial)

  const automaticSelectionHandler = () => {
    if (!selected) {
      const option = options[options.length - 1]
      setSelected(option.id)
      onClick(option.id)
    } else {
      onClick(selected)
    }
  }

  return (
    <div className={`custom-select ${active ? "active" : ""}`} onClick={automaticSelectionHandler}>
      <div className="custom-select-label" title={name}>
        {name}
      </div>
      <select
        name={name}
        defaultValue={selected || options[options.length - 1].id}
        onChange={event => {
          setSelected(event.target.value)
          onChange(event.target.value)
        }}
      >
        {blank && (
          <option disabled value="">
            {blank}
          </option>
        )}
        {options.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  )
}
