import React from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { REGION_FRAGMENT, GetRegions, GetRegionsVariables, Region } from "../../graph"
import _ from "../../utils/_"
import { Loading } from ".."
import { useOneOfMany } from "../../hooks/selection"

const GET_REGIONS = gql`
  query GetRegions($providerId: ID!) {
    regions(providerId: $providerId) {
      ...Region
    }
  }

  ${REGION_FRAGMENT}
`

export function RegionsSelect({ providerId, onSelect }: { providerId: string; onSelect(region: Region): void }) {
  const [selected, select] = useOneOfMany()
  const { data, loading, error } = useQuery<GetRegions, GetRegionsVariables>(GET_REGIONS, {
    variables: { providerId }
  })

  if (error) throw error
  if (loading || !data) return <Loading />

  const originSelected = (origin: string) => (data.regions || []).find(r => selected[r.id])?.origin === origin

  const setSelected = (region: Region) => {
    select(region.id)
    onSelect(region)
  }

  return (
    <div className="region-select-container">
      {_.mapByGroup<Region>(data.regions, "origin", ([origin, regions]) => (
        <div key={origin}>
          <div className={`region-select ${originSelected(origin) ? "active" : ""}`}>
            <button type="button" className="main-option" onClick={() => setSelected(regions[0])}>
              {origin}
            </button>

            <div className="region-options">
              {regions.map((region: Region, index: number) => (
                <button
                  key={region.id}
                  type="button"
                  className={`region-option ${selected[region.id] ? "active" : ""}`}
                  onClick={() => setSelected(region)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
