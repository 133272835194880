import React from "react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

SwiperCore.use([Navigation])

export function FeaturesTour() {
  return (
    <div className="features-tour">
      <header>
        <p>
          We resell servers powered by <strong>DigitalOcean</strong>, <strong>Linode</strong> and
          <strong>Vultr</strong>. Services with different functionalities and limitations, yet we managed to provide a
          single, powerful and easy to use interface to create and manage servers.
        </p>

        <p>
          You can <strong>Resize</strong>, <strong>Rebuild</strong> and <strong>Backup</strong> servers from any
          provider with ease!
        </p>
      </header>

      <div className="tour">
        <Swiper navigation>
          <SwiperSlide className="feature-slide">
            <Feature description="Creating DigitalOcean droplets." picture="create-do" />
          </SwiperSlide>

          <SwiperSlide className="feature-slide">
            <Feature description="Creating Linode instances." picture="create-linode" />
          </SwiperSlide>

          <SwiperSlide className="feature-slide">
            <Feature description="Creating Vultr servers." picture="create-vultr" />
          </SwiperSlide>

          <SwiperSlide className="feature-slide">
            <Feature description="The details of a bithost server." picture="details" />
          </SwiperSlide>

          <SwiperSlide className="feature-slide">
            <Feature description="Rebuilding a bithost server." picture="rebuild" />
          </SwiperSlide>

          <SwiperSlide className="feature-slide">
            <Feature description="Resizing a bithost server." picture="resize" />
          </SwiperSlide>

          <SwiperSlide className="feature-slide">
            <Feature description="Managing backups for a bithost server." picture="backups" />
          </SwiperSlide>

          <SwiperSlide className="feature-slide">
            <Feature description="Activity for a bithost server." picture="events" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  )
}

function Feature({ description, picture }: { description: string; picture: string }) {
  return (
    <figure>
      <div className={`picture ${picture}`} />
      <figcaption className="text-muted">{description}</figcaption>
    </figure>
  )
}
