import React from "react"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import { DangerousOperation, Controls } from ".."
import * as route from "../../route"
import { SERVER_FRAGMENT, ERROR_FRAGMENT, DeleteServer as DeleteServerData, DeleteServerVariables } from "../../graph"

const DELETE_SERVER = gql`
  mutation DeleteServer($input: ServerDeleteInput!) {
    serverDelete(input: $input) {
      node {
        ...Server
      }
      errors {
        ...Error
      }
    }
  }

  ${SERVER_FRAGMENT}
  ${ERROR_FRAGMENT}
`

export function DeleteServer({ id, disabled }: { id: string; disabled: boolean }) {
  const [deleteServer] = useMutation<DeleteServerData, DeleteServerVariables>(DELETE_SERVER)

  const promptForDeletionHandler = (event: React.SyntheticEvent, open: Controls["open"]) => {
    event.preventDefault()
    if (!disabled) open()
  }

  const actualDeletionHandler = async (close: Controls["close"]) => {
    await deleteServer({
      variables: { input: { id } }
    })

    close()
    route.visit(route.servers())
  }

  return (
    <DangerousOperation
      warning="The server will be deleted!"
      confirm={({ close }: Controls) => (
        <button type="button" className="btn-danger" onClick={() => actualDeletionHandler(close)}>
          Continue
        </button>
      )}
    >
      {({ open }: Controls) => (
        <a
          className={`delete ${disabled ? "disabled" : ""}`}
          rel="nofollow"
          href={route.servers()}
          onClick={event => promptForDeletionHandler(event, open)}
        >
          Delete
        </a>
      )}
    </DangerousOperation>
  )
}
