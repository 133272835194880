import { useState } from "react"

type OneOfManyResult = [any, (value: any) => void]

export function useOneOfMany(): OneOfManyResult {
  const [selected, setSelected] = useState<any>({})
  const select = (value: any) => setSelected({ [value]: true })

  return [selected, select]
}
