import React from "react"

export function PageTitle({
  title,
  renderSubline,
  renderLeft,
  className = ""
}: {
  title: string
  className?: string
  renderLeft?: () => React.ReactNode
  renderSubline?: () => React.ReactNode
}) {
  return (
    <div className={`page-title ${className}`}>
      <div className="page-title-inner">
        {renderLeft && <div className="section-left">{renderLeft()}</div>}

        <div className="title">
          <h1>{title}</h1>
          {renderSubline && <div className="subline">{renderSubline()}</div>}
        </div>
      </div>
    </div>
  )
}
