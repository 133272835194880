import React, { useState } from "react"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import { Alert } from ".."
import { SERVER_FRAGMENT, ERROR_FRAGMENT, ClearServerMessage, ClearServerMessageVariables } from "../../graph"

const CLEAR_SERVER_MESSAGE = gql`
  mutation ClearServerMessage($input: ServerClearMessageInput!) {
    serverClearMessage(input: $input) {
      node {
        ...Server
      }
      errors {
        ...Error
      }
    }
  }

  ${SERVER_FRAGMENT}
  ${ERROR_FRAGMENT}
`

export function OperationError({ id, message, failed }: { id: string; message: string; failed: boolean }) {
  const [dismissed, setDismissed] = useState(false)
  const [clearServerMessage] = useMutation<ClearServerMessage, ClearServerMessageVariables>(CLEAR_SERVER_MESSAGE)

  const closeHandler = async () => {
    await clearServerMessage({
      variables: { input: { id } }
    })

    setDismissed(true)
  }

  if (dismissed) return null

  return (
    <div className="spacing-bottom-sm">
      <Alert type="error" onClose={failed ? undefined : closeHandler}>
        {message}
      </Alert>
    </div>
  )
}
