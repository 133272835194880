import React from "react"
import { OnOff } from "."

export function AutomaticBackups({
  value,
  onChange,
  disabled,
  chargeMessage = "Charged at 20% of the server price"
}: {
  value: any
  onChange: React.ChangeEventHandler
  disabled?: boolean
  chargeMessage?: string
}) {
  return (
    <>
      <label className="control-label">Automatic backups</label>

      <p className="text-muted">
        <small>{chargeMessage}</small>
      </p>

      <OnOff value={value} onChange={onChange} disabled={disabled} />
    </>
  )
}
