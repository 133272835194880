import React from "react"

export function Segments<T = string>({
  items,
  active,
  onChange,
  lg = "",
  format = item => `${item}`
}: {
  items: readonly T[]
  active: T
  onChange: (item: T) => void
  lg?: string
  format?: (item: T) => string
}) {
  return (
    <div className="segments-wrapper">
      <div className={`segments ${lg}`}>
        {items.map(item => (
          <button
            key={format(item)}
            type="button"
            className={`segment-item ${active === item ? "selected" : ""}`}
            onClick={() => onChange(item)}
          >
            {format(item)}
          </button>
        ))}
      </div>
    </div>
  )
}
