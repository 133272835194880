export const Toggle = {
  initialize() {
    const toggles = document.querySelectorAll(".js-toggle[data-target]")

    toggles.forEach(toggle => {
      const { target } = (toggle as HTMLElement).dataset
      if (target == null) throw new Error(`${toggle} does not have a valid data-target`)

      const targetElement = document.querySelector(target!)
      if (targetElement == null) throw new Error(`Cannot find target ${target}`)

      toggle.addEventListener("click", event => {
        event.preventDefault()

        const hidden = targetElement.getAttribute("hidden")
        if (hidden == null) {
          targetElement.setAttribute("hidden", "")
        } else {
          targetElement.removeAttribute("hidden")
        }
      })
    })
  }
}
