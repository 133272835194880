import { useEffect, useState } from "react"

const resourceCache:
  | Record<string, { state: "fetching" }>
  | Record<string, { state: "fetched"; data: any }>
  | Record<string, { state: "failed"; error: any }> = {}

export function useCachedResource<T>(input: string) {
  const [resource, setResource] = useState<{ data: T | null; error: any }>({
    data: null,
    error: null
  })

  useEffect(() => {
    const cache = resourceCache[input]
    switch (cache?.state) {
      case "fetching": {
        const handle = window.setInterval(() => {
          const futureCache = resourceCache[input]
          if (futureCache.state === "fetched") {
            setResource({ data: futureCache.data, error: null })
            window.clearInterval(handle)
          }
          if (futureCache.state === "failed") {
            setResource({ data: null, error: futureCache.error })
            window.clearInterval(handle)
          }
        }, 50)

        return () => window.clearInterval(handle)
      }
      case "fetched": {
        setResource({ data: cache.data, error: null })
        return
      }
      case "failed": {
        setResource({ data: null, error: cache.error })
        return
      }
      default: {
        const fetchResource = async () => {
          try {
            resourceCache[input] = { state: "fetching" }

            const response = await fetch(input)
            const data = await response.json()

            resourceCache[input] = { state: "fetched", data }
            setResource({ data, error: null })
          } catch (error) {
            resourceCache[input] = { state: "failed", error }
            setResource({ error, data: null })
          }
        }

        fetchResource()
      }
    }
  }, [input])

  const cache = resourceCache[input]
  return { ...resource, loading: cache == null || cache.state === "fetching" }
}
