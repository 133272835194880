import React, { useState } from "react"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import * as route from "../../route"
import { SERVER_FRAGMENT, ERROR_FRAGMENT } from "../../graph"
import { Progress } from "."
import { Tooltip } from ".."

const SERVER_SNAPSHOT = gql`
  mutation SnapshotServer($input: ServerSnapshotInput!) {
    serverSnapshot(input: $input) {
      node {
        ...Server
      }
      errors {
        ...Error
      }
    }
  }

  ${SERVER_FRAGMENT}
  ${ERROR_FRAGMENT}
`

export function SnapshotForm({ id, pending }: { id: string; pending: boolean }) {
  const [name, setName] = useState("")
  const [progress, setProgress] = useState(pending)
  const [snapshotServer] = useMutation(SERVER_SNAPSHOT)

  const handleSnapshotSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    await snapshotServer({
      variables: {
        input: { id, name }
      }
    })

    setProgress(true)
    route.visit(route.serverBackups(id))
  }

  const tooltipContent = !name ? "Snapshots need a name" : "Server is busy"

  return (
    <form className="flex-container" onSubmit={handleSnapshotSubmit}>
      {progress && <Progress id={id} />}

      <div className="form-group">
        <label className="control-label">Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Snapshot name..."
          value={name}
          onChange={event => setName(event.target.value)}
        />
        <small className="help-block">Snapshots cost $0.06/GB per month</small>
      </div>

      <div className="buttons-wrapper">
        <Tooltip content={tooltipContent} disabled={!!name && !progress}>
          <button className="btn-primary" type="submit" disabled={name === "" || pending}>
            Create snapshot
          </button>
        </Tooltip>
      </div>
    </form>
  )
}
