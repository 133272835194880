import React from "react"

export function ListSelect<T extends { id: string }>({
  className = "",
  items,
  selected,
  format,
  disabled,
  onChange,
  empty = null
}: {
  items: T[]
  selected: string | null
  format(item: T): JSX.Element
  disabled?: string
  className?: string
  onChange: (id: string) => any
  empty?: JSX.Element | null
}) {
  return (
    <div className={`list-select ${className}`}>
      {items.length === 0 && empty}
      {items.map(item => (
        <div
          key={item.id}
          className={`item ${selected === item.id ? "selected" : ""} ${disabled === item.id ? "disabled" : ""}`}
          onClick={() => onChange(item.id)}
        >
          {format(item)}
        </div>
      ))}
    </div>
  )
}
