import consumer from "./consumer"
import * as route from "../route"

interface ServerEventData {
  id: string
}

export const ServerEventChannel = {
  activeSubscriptions: {} as Record<string, ActionCable.Channel>,

  await(id: string) {
    let subscription = this.activeSubscriptions[id]
    if (subscription) return subscription

    subscription = consumer.subscriptions.create(
      { channel: "ServerEventChannel", id },
      {
        received: (data: ServerEventData) => {
          delete this.activeSubscriptions[id]

          const currentLocation = document.location.href
          if (currentLocation.includes(route.server(data.id))) {
            route.visit(currentLocation, { action: "replace" })
          }
        }
      }
    )
    this.activeSubscriptions[id] = subscription

    return subscription
  }
}
