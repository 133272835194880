import React, { useState } from "react"
import * as route from "../../route"

export function GDPR() {
  const [loading, setLoading] = useState(false)
  const [closed, close] = useState(false)

  const acceptHandler = async () => {
    setLoading(true)
    try {
      await fetch(route.gdpr(), { method: "POST" })
      close(true)
    } finally {
      setLoading(false)
    }
  }

  const denyHandler = async () => {
    setLoading(true)
    try {
      await fetch(route.gdpr(), { method: "DELETE" })
      close(true)

      window.location.assign(route.root())
    } finally {
      setLoading(false)
    }
  }

  if (closed) return null

  return (
    <div className="gdpr">
      <div className="flex-container space-between">
        <p>
          This site uses cookies to track registrations and login users. Read our{" "}
          <a href={route.privacy()} data-turbolinks="false">
            policy
          </a>{" "}
          and accept or reject our usage.
        </p>
        <div className="buttons-wrapper">
          <button type="button" className="btn-primary sm" disabled={loading} onClick={acceptHandler}>
            Agree
          </button>
          <button type="button" className="btn-danger sm" disabled={loading} onClick={denyHandler}>
            Deny
          </button>
        </div>
      </div>
    </div>
  )
}
