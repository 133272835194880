import * as Sentry from "@sentry/browser"

const stopExecution = process.env.NODE_ENV === "development"

export function assert(condition: any, message?: string, metadata?: any): asserts condition {
  if (condition) return condition

  assertionFailure(message, metadata)

  return condition
}

export function assertionFailure(message?: string, metadata?: any) {
  const error = new Error(message || "Assertion failure")
  if (metadata) Sentry.configureScope(scope => scope.setExtras(metadata))

  if (stopExecution) {
    console.error(error) // eslint-disable-line no-console
    throw error
  } else {
    Sentry.captureException(error)
  }
}

export function precondition(condition: any, message?: string, metadata?: any): asserts condition {
  if (condition) return condition

  preconditionFailure(message, metadata)
}

export function preconditionFailure(message?: string, metadata?: any): never {
  const error = new Error(message || "Precondition failure")
  if (metadata) Sentry.configureScope(scope => scope.setExtras(metadata))

  console.error(error) // eslint-disable-line no-console
  throw error
}
