const MutationObserverIntercomLayout = {
  observer: new MutationObserver(mutations => {
    mutations.forEach(record => {
      record.addedNodes.forEach(node => {
        if (node instanceof HTMLElement && node.className === "intercom-namespace") {
          toggleIntercomElement("none", node)
        }
      })
    })
  }),

  observerOptions: { childList: true, attributes: false, subtree: false },

  initialize() {
    this.observer.observe(document.body, this.observerOptions)

    window.setTimeout(() => {
      this.observer.disconnect()
      toggleIntercomElement("initial")
    }, 3000)
  },

  finalize() {
    toggleIntercomElement("none")
    this.observer.observe(document.body, this.observerOptions)
  }
}

const PollingIntercomLayout = {
  initialize() {
    const hopefulInvisibiltyInterval = window.setInterval(() => {
      toggleIntercomElement("none")
    }, 100)

    window.setTimeout(() => {
      window.clearInterval(hopefulInvisibiltyInterval)
      toggleIntercomElement("initial")
    }, 3000)
  },

  finalize() {
    toggleIntercomElement("none")
  }
}

export const IntercomLayout = window.MutationObserver ? MutationObserverIntercomLayout : PollingIntercomLayout

function toggleIntercomElement(
  display: "initial" | "none",
  element = document.querySelector<HTMLElement>(".intercom-namespace")
) {
  if (element != null) {
    element.style.display = display
  }
}
