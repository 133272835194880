import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

export const DOMMobileNavigation = {
  initialize() {
    const trigger = document.querySelector(".menu-trigger")

    trigger?.addEventListener("click", () => {
      const header = document.querySelector(".header")!
      const isOpen = header?.classList.contains("open")

      if (isOpen) {
        enableBodyScroll(header)
      } else {
        disableBodyScroll(header)
      }

      header.classList.toggle("open", !isOpen)
      trigger.classList.toggle("close-trigger", !isOpen)
    })
  }
}
