import React from "react"

export function Toggle({
  value,
  checked,
  onChange,
  disabled,
  label = ""
}: {
  label?: string
  value: any
  checked: boolean
  disabled?: boolean
  onChange: React.ChangeEventHandler
}) {
  return (
    <label className={`toggle ${disabled ? "disabled" : ""}`}>
      {label}
      <input
        type="checkbox"
        className="toggle-input"
        value={value}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <span className="toggle-label" />
      <span className="toggle-handle" />
    </label>
  )
}
