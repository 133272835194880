import ClipboardJS from "clipboard"

export const Copy = {
  clipboard: null as ClipboardJS | null,

  initialize() {
    this.clipboard = new ClipboardJS(".js-clipboard")
  },

  finalize() {
    if (this.clipboard) this.clipboard.destroy()
    this.clipboard = null
  }
}
