import Turbolinks from "turbolinks"

export function root() {
  return "/"
}

export function servers() {
  return "/servers"
}

export function server(id: string) {
  return `/servers/${id}`
}

export function serverBackups(id: string) {
  return `/servers/${id}/backups`
}

export function serverPower(id: string) {
  return `/servers/${id}/power`
}

export function serverRebuild(id: string) {
  return `/servers/${id}/rebuild`
}

export function serverResize(id: string) {
  return `/servers/${id}/resize`
}

export function serverSnapshot(id: string) {
  return `/servers/${id}/snapshot`
}

export function serverRename(id: string) {
  return `/servers/${id}/rename`
}

export function keysNew() {
  return `/keys/new`
}

export function images(params: QueryParams) {
  return `/images${q(params)}`
}

export function sizes(params: QueryParams) {
  return `/sizes${q(params)}`
}

export function gdpr() {
  return "/gdpr"
}

export function privacy() {
  return "https://bithost.io/privacy"
}

interface QueryParams {
  [key: string]: string
}

function q(params: QueryParams) {
  return params != null ? `?${new URLSearchParams(params)}` : ""
}

interface VisitOptions {
  delay?: number
  action?: "advance" | "replace"
}

const defaults: VisitOptions = { delay: 200, action: "advance" }

export function visit(path: string, { delay = defaults.delay, action = defaults.action }: VisitOptions = defaults) {
  if (delay! > 0) {
    setTimeout(() => visit(path, { delay: 0, action: action! }), delay)
  } else if (Turbolinks) {
    Turbolinks.clearCache()
    Turbolinks.visit(path, { action: action! })
  } else {
    window.location.assign(path)
  }
}

export function preventVisits(prevent: boolean = true) {
  const globalTurbolinks: any = Turbolinks
  globalTurbolinks.prevented = prevent
}
