import gql from "graphql-tag"

export * from "./types"

export const ERROR_FRAGMENT = gql`
  fragment Error on Error {
    field
    codes
  }
`

export const IMAGE_FRAGMENT = gql`
  fragment Image on Image {
    id
    name
    distribution
    windows
    architecture
  }
`

export const KEY_FRAGMENT = gql`
  fragment Key on Key {
    id
    key
    label
  }
`

export const PROVIDER_FRAGMENT = gql`
  fragment Provider on Provider {
    id
    name
    logoUrl
  }
`

export const SERVER_FRAGMENT = gql`
  fragment Server on Server {
    id
    name
    pending
    power
    costSoFar
    status
    backupsEnabled
    message
    ipAddress
    privateIpAddress
    ipAddressV6
    privateIpAddressV6
    providerId
  }
`

export const SIZE_FRAGMENT = gql`
  fragment Size on Size {
    id
    name
    slug
    price
    pricePerHour
    windowsFee
    memory
    processor
    bandwidth
    disk
    kind
  }
`

export const REGION_FRAGMENT = gql`
  fragment Region on Region {
    id
    name
    slug
    origin
    number
    availableSizeSlugs
  }
`
