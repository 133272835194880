import React, { useState } from "react"

interface Item {
  id: string | number
  title: string
  content: string
}

export function Accordion({ items, initial }: { items: Item[]; initial: string | number }) {
  const [current, setCurrent] = useState(initial)

  return (
    <div className="accordion">
      {items.map(item => (
        <div key={item.id} className={`accordion-row ${current === item.id ? "open" : ""}`}>
          <button type="button" className="accordion-row-toggle" onClick={() => setCurrent(item.id)}>
            {item.title}
          </button>
          <div className="accordion-row-content">{item.content}</div>
        </div>
      ))}
    </div>
  )
}
