import React from "react"
import { Toggle } from ".."

export function OnOff({
  value,
  onChange,
  disabled,
  className = ""
}: {
  value: any
  onChange: React.ChangeEventHandler
  className?: string
  disabled?: boolean
}) {
  return (
    <div className={`${className} ${disabled ? "disabled" : ""}`}>
      <Toggle value={value} checked={value} onChange={onChange} disabled={disabled} />{" "}
      <strong className="text-muted">{value ? "ON" : "OFF"}</strong>
    </div>
  )
}
