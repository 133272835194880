import React, { useState } from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { KEY_FRAGMENT, GetKeys } from "../../graph"
import { Loading, LinkModal, useModalOpen } from ".."
import * as route from "../../route"

const GET_KEYS = gql`
  query GetKeys {
    keys {
      ...Key
    }
  }

  ${KEY_FRAGMENT}
`

export function KeysSelect({ onSelect }: { onSelect(ids: string[]): void }) {
  const [selected, select] = useSelection()
  const [isOpen, open, close] = useModalOpen()
  const { data, loading, error, refetch } = useQuery<GetKeys>(GET_KEYS)

  const newKeyHandler: React.ReactEventHandler = event => {
    event.preventDefault()
    open()
  }

  const closeHandler = () => {
    refetch()
    close()
  }

  if (error) throw error
  if (loading) {
    return <Loading />
  }

  return (
    <div className="keys-select">
      <div className="form-group radio-group">
        {data!.keys.map(key => (
          <label key={key.id} className={`control-label ${selected.find(k => k === key.id) != null ? "selected" : ""}`}>
            <input
              type="checkbox"
              name="option"
              onChange={event => {
                select(key.id, event.target.checked, k => onSelect(k))
              }}
            />
            {key.label}
          </label>
        ))}
      </div>

      <small>
        <a target="_blank" rel="noopener" href="#" className="btn-inner" onClick={newKeyHandler}>
          Add SSH Key
        </a>
        <LinkModal title="Add SSH Key" closeOnRedirect isOpen={isOpen} onClose={closeHandler} href={route.keysNew()} />
      </small>
    </div>
  )
}

type SelectionState = { [key: string]: boolean }
type SelectionCallback = (selected: string[]) => void
type SelectionFunc = (key: string, checked: boolean, callback?: SelectionCallback) => void
type Selection = [string[], SelectionFunc]

function useSelection(): Selection {
  const [state, setState] = useState<SelectionState>({})

  const filterSelected = (selectionState: SelectionState): string[] =>
    Object.entries(selectionState)
      .filter(([, checked]) => checked)
      .map(([id]) => id)

  const select = (key: string, checked: boolean, callback?: SelectionCallback) =>
    setState(prev => {
      const nextState = { ...prev, [key]: checked }
      if (callback) callback(filterSelected(nextState))
      return nextState
    })

  return [filterSelected(state), select]
}
