type ClassName = string | undefined | null | false | Record<string, boolean>

export function classnames(...args: ClassName[]) {
  const classNames: ClassName[] = []

  args.forEach(cls => {
    if (!cls) return

    if (typeof cls === "string") {
      classNames.push(cls)
    } else if (typeof cls === "object") {
      Object.keys(cls).forEach(key => {
        if (cls[key]) classNames.push(key)
      })
    }
  })

  return classNames.join(" ")
}
