import React from "react"
import Tippy, { TippyProps } from "@tippyjs/react"

export function Tooltip({ children, ...rest }: TippyProps) {
  return (
    <Tippy {...rest}>
      <span className="tooltip">{children}</span>
    </Tippy>
  )
}
