import React, { useState } from "react"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import * as route from "../../route"
import { AutomaticBackups, SnapshotForm, Progress } from "."
import { DangerousOperation, Controls } from ".."
import { Server, ERROR_FRAGMENT, SERVER_FRAGMENT, BackupServer, BackupServerVariables } from "../../graph"

const SERVER_BACKUP = gql`
  mutation BackupServer($input: ServerBackupInput!) {
    serverBackup(input: $input) {
      node {
        ...Server
      }
      errors {
        ...Error
      }
    }
  }

  ${SERVER_FRAGMENT}
  ${ERROR_FRAGMENT}
`

export function BackupForm({ id, pending, backupsEnabled }: { id: string; pending: boolean; backupsEnabled: boolean }) {
  const [backups, setBackups] = useState(backupsEnabled)
  const [progress, setProgress] = useState(pending)

  const [backupServer] = useMutation<BackupServer, BackupServerVariables>(SERVER_BACKUP)

  const backupsHandler = async (close: Function) => {
    const response = await backupServer({
      variables: {
        input: { id, automatic: !backups }
      }
    })

    setProgress(true)
    setBackups((response.data?.serverBackup?.node as Server).backupsEnabled)
    close()

    route.visit(route.serverBackups(id))
  }

  const backupsTriggerHandler = (open: Function, close: Function) => {
    if (!backups) {
      open()
    } else {
      backupsHandler(close)
    }
  }

  return (
    <div className="spacing-bottom">
      {progress && <Progress id={id} />}

      <div className="row">
        <div className="col-6">
          <DangerousOperation
            warning="Automatic backups cost 20% more..."
            confirm={({ close }: Controls) => (
              <button type="button" className="btn-danger" onClick={() => backupsHandler(close)}>
                Continue
              </button>
            )}
          >
            {({ open, close }: Controls) => (
              <AutomaticBackups
                value={Boolean(backups)}
                disabled={progress}
                onChange={() => backupsTriggerHandler(open, close)}
              />
            )}
          </DangerousOperation>
        </div>

        <div className="col-6">
          <SnapshotForm id={id} pending={pending} />
        </div>
      </div>
    </div>
  )
}
