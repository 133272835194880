import React, { useRef, useState } from "react"
import { createPortal } from "react-dom"
import { Modal } from "bootstrap"

const BOOTSTRAP_MODAL_SIZES = {
  sm: "modal-sm",
  lg: "modal-lg",
  xl: "modal-xl"
}

export const BootstrapModal = React.forwardRef<
  HTMLDivElement,
  {
    size?: "sm" | "lg" | "xl"
    title: React.ReactNode
    children: React.ReactNode
  }
>(({ size, title, children }, ref) => {
  const modalSize = BOOTSTRAP_MODAL_SIZES[size || "lg"]

  return createPortal(
    <div ref={ref} className="modal fade" tabIndex={-1} role="dialog">
      <div className={`modal-dialog ${modalSize}`} role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.body
  )
})

export function useBootstrapModal() {
  const modalRef = useRef<HTMLDivElement>(null)
  const [modalShown, setModalShown] = useState(false)

  return {
    modalRef,
    modalShown,
    showModal() {
      setModalShown(true)

      window.setTimeout(() => {
        if (modalRef.current) {
          const modal = new Modal(modalRef.current)
          modal.show()
        }
      }, 0)
    }
  }
}
