import React from "react"
import { useModalOpen, LinkModal } from ".."
import * as route from "../../route"

export function RenameForm({ id, name, disabled }: { id: string; name: string; disabled?: boolean }) {
  const [isOpen, open, close] = useModalOpen()

  return (
    <span className="rename">
      <LinkModal title="Rename server" href={route.serverRename(id)} isOpen={isOpen} onClose={close} />
      {name} {!disabled && <i className="icon edit" onClick={open} />}
    </span>
  )
}
