import React from "react"
import { Modal, useModalOpen } from "."

export interface Controls {
  open: () => void
  close: () => void
}

type ControlsFn = (controls: Controls) => React.ReactNode

export function DangerousOperation({
  children,
  warning,
  confirm,
  cancel = ({ close }) => (
    <button type="button" className="btn-text" onClick={close}>
      Cancel
    </button>
  ),
  title = "Are you sure?",
  size = "auto",
  ...rest
}: {
  title?: string
  warning: string | JSX.Element
  size?: string
  confirm: ControlsFn
  cancel?: ControlsFn
  children: ControlsFn
}) {
  const [isOpen, open, close] = useModalOpen()

  return (
    <>
      {children({ open, close })}

      <Modal isOpen={isOpen} onClose={close} title={title} {...rest} size={size}>
        {warning && <p className="text-danger">{warning}</p>}

        <div className="button-wrapper">
          {confirm({ open, close })}
          {cancel({ open, close })}
        </div>
      </Modal>
    </>
  )
}
