import { Size } from "../graph"
import {
  DigitalOceanProvider,
  PacketProvider,
  LinodeProvider,
  HetznerProvider,
  VultrProvider,
  formatUSD,
  formatFixedNumber
} from "."

export const DigitalOceanSizeKinds = [
  "Basic",
  "Premium Intel",
  "Premium AMD",
  "General Purpose",
  "CPU-Optimized",
  "Memory-Optimized",
  "Storage-Optimized"
] as const

export const PacketSizeKinds = ["Standard"] as const
export const LinodeSizeKinds = ["Nanode", "Standard", "Dedicated CPU", "High Memory", "GPU"] as const
export const HetznerSizeKinds = ["Shared ARM", "Shared Intel", "Shared AMD", "Dedicated AMD"] as const
export const VultrSizeKinds = ["Cloud Compute", "High Frequency", "Dedicated Cloud"] as const

export type SizeKind =
  | typeof DigitalOceanSizeKinds[number]
  | typeof PacketSizeKinds[number]
  | typeof LinodeSizeKinds[number]
  | typeof HetznerSizeKinds[number]
  | typeof VultrSizeKinds[number]

export function sizeKindsForProvider(id: string | undefined): readonly SizeKind[] {
  switch (id) {
    case DigitalOceanProvider:
      return DigitalOceanSizeKinds
    case PacketProvider:
      return PacketSizeKinds
    case LinodeProvider:
      return LinodeSizeKinds
    case HetznerProvider:
      return HetznerSizeKinds
    case VultrProvider:
      return VultrSizeKinds
    default:
      return []
  }
}

export type SizeFormat = "memory" | "storage" | "processor" | "cost" | "costPerHour" | "detail" | "bandwidth"

export function formatSize(size: Size, format: SizeFormat): string {
  switch (format) {
    case "memory":
      return formatMemory(size.memory)
    case "storage":
      return formatStorage(size.disk)
    case "processor":
      return formatProcessor(size.processor)
    case "cost":
      return formatUSD(size.price)
    case "costPerHour":
      return formatUSD(size.pricePerHour, 3)
    case "bandwidth":
      return formatStorage(size.bandwidth)
    case "detail":
      return (
        `${formatMemory(size.memory)} / ` +
        `${formatProcessor(size.processor)} / ` +
        `${formatStorage(size.disk)} Storage / ` +
        `${formatStorage(size.bandwidth)} Traffic`
      )
    default:
      return ""
  }
}

export function isARMSizeKind(sizeKind: SizeKind | undefined): boolean {
  return sizeKind === "Shared ARM"
}

const memoryThreshold = 1024
const memoryAlreadyFormatted = /(MB)|(GB)|(TB)/i

function formatMemory(randomAccessMemory: string | number) {
  if (memoryAlreadyFormatted.test(randomAccessMemory as string)) return `${randomAccessMemory}`

  randomAccessMemory = Number(randomAccessMemory)
  if (randomAccessMemory < memoryThreshold) return `${randomAccessMemory} MB`

  return `${randomAccessMemory / memoryThreshold} GB`
}

const processorAlreadyFormatted = /vCPU|Cores?/gi

function formatProcessor(processor: string) {
  if (processorAlreadyFormatted.test(processor)) {
    return processor.replace(processorAlreadyFormatted, Number.parseInt(processor, 10) === 1 ? "Core" : "Cores")
  }

  return `${processor} ${Number.parseInt(processor, 10) === 1 ? "Core" : "Cores"}`
}

function formatStorage(storage: string | number) {
  if (memoryAlreadyFormatted.test(storage as string)) return `${storage}`

  storage = Number(storage)
  if (storage >= 1024) return `${formatFixedNumber(storage / 1024)} TB`

  return `${formatFixedNumber(storage)} GB`
}
