import React, { useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { OnOff, Progress } from "."
import { DangerousOperation, Controls, Tooltip } from ".."
import { Power, Server, SERVER_FRAGMENT, PowerServer, PowerServerVariables, ERROR_FRAGMENT } from "../../graph"

const POWER_SERVER = gql`
  mutation PowerServer($input: ServerPowerInput!) {
    serverPower(input: $input) {
      node {
        ...Server
      }
      errors {
        ...Error
      }
    }
  }

  ${SERVER_FRAGMENT}
  ${ERROR_FRAGMENT}
`

export function PowerManagement({ server }: { server: Server }) {
  const [power, setPower] = useState(server.status === "active" && server.power)
  const [progress, setProgress] = useState(server.pending)
  const [powerServer] = useMutation<PowerServer, PowerServerVariables>(POWER_SERVER)

  const powerHandler = async (close: Function) => {
    const response = await powerServer({
      variables: {
        input: { id: server.id, action: power ? Power.OFF : Power.ON }
      }
    })

    setProgress(true)
    setPower((response.data?.serverPower?.node as Server)?.power ?? power)
    close()
  }

  const rebootHandler = async (event: React.MouseEvent, close: Function) => {
    event.preventDefault()

    await powerServer({
      variables: {
        input: { id: server.id, action: Power.REBOOT }
      }
    })

    setProgress(true)
    close()
  }

  return (
    <div className="power-management">
      <div className="flex-container">
        {progress && <Progress id={server.id} />}

        <DangerousOperation
          warning={`The server will be powered ${power ? "off" : "on"}...`}
          confirm={({ close }: Controls) => (
            <button type="button" className="btn-danger" onClick={() => powerHandler(close)}>
              Continue
            </button>
          )}
        >
          {({ open }: Controls) => (
            <Tooltip content="Server is busy" disabled={!progress}>
              <OnOff className="on-off" value={power} onChange={open} disabled={progress} />
            </Tooltip>
          )}
        </DangerousOperation>

        <DangerousOperation
          warning="The server will be restarted..."
          confirm={({ close }: Controls) => (
            <button
              type="button"
              className="btn-danger"
              onClick={event => rebootHandler(event, close)}
              disabled={progress}
            >
              Continue
            </button>
          )}
        >
          {({ open }: Controls) => (
            <Tooltip content="Server is busy" disabled={!progress}>
              <button type="button" className="btn-text button-reset" onClick={open} disabled={progress}>
                <i className="icon reset" /> Restart
              </button>
            </Tooltip>
          )}
        </DangerousOperation>
      </div>
    </div>
  )
}
