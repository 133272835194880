import React, { useState, useRef, useEffect } from "react"
import { DangerousOperation, Controls } from ".."

export function RegistrationSubmit({ hCaptchaSiteKey }: { hCaptchaSiteKey: string }) {
  const [, accept] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://hcaptcha.com/1/api.js"
    script.async = true

    document.head.appendChild(script)
  }, [])

  const submitHandler = (event: React.FormEvent, open: Controls["open"]) => {
    accept(accepted => {
      if (!accepted) {
        event.preventDefault()
        open()
      }

      return accepted
    })
  }

  const unlockSubmitHandler = () => {
    accept(true)
    inputRef.current?.click()
  }

  return (
    <DangerousOperation
      title="Terms of Service"
      warning={(
        <>
          Creating multiple accounts will result in a ban with no refund. Please read and accept our{" "}
          <a href="https://bithost.io/terms" target="_blank" rel="noopener noreferrer">
            terms of service
          </a>{" "}
          before registering.
        </>
      )}
      confirm={() => (
        <label htmlFor="registration-submit">
          <span className="btn-danger" onClick={unlockSubmitHandler}>
            Accept
          </span>
        </label>
      )}
      size="50%"
    >
      {({ open }: Controls) => (
        <>
          <input
            hidden
            id="registraton-submit"
            ref={inputRef}
            type="submit"
            name="commit"
            className="h-captcha"
            data-sitekey={hCaptchaSiteKey}
            data-callback="hCaptchaSubmit"
          />
          <button
            type="button"
            className="btn-primary"
            data-disable-with="Register"
            onClick={event => submitHandler(event, open)}
          >
            Register
          </button>
        </>
      )}
    </DangerousOperation>
  )
}
