import React from "react"

export function PreventRegistration({ disabled }: { disabled: boolean }) {
  const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute("content") ?? ""

  return (
    <div className="custom-control custom-switch">
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          checked={disabled}
          id="registration-switch"
          onChange={() => {
            fetch("/admin/users/registration", {
              method: disabled ? "POST" : "DELETE",
              headers: { "X-CSRF-Token": csrfToken }
            }).then(() => {
              window.location.reload()
            })
          }}
        />
        <label className="form-check-label text-secondary" htmlFor="registration-switch">
          Prevent Registrations
        </label>
      </div>
    </div>
  )
}
