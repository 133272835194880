import React from "react"

export function Alert({
  type = "none",
  children,
  className = "",
  onClose
}: {
  type: "none" | "notice" | "success" | "error"
  className?: string
  children: React.ReactNode
  onClose?(): void
}) {
  const closeHandler: React.MouseEventHandler = event => {
    event.preventDefault()

    onClose?.()
  }

  return (
    <div className={`alert ${type === "none" ? "" : type} ${className}`}>
      {children}
      {onClose && (
        <button type="button" onClick={closeHandler}>
          &times;
        </button>
      )}
    </div>
  )
}
