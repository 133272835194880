import React, { Fragment } from "react"
import { BootstrapModal, useBootstrapModal } from "."
import { Loading } from ".."
import { useCachedResource } from "../../utils"

export function WhoisModal({ address }: { address: string }) {
  const { modalRef, showModal } = useBootstrapModal()

  return (
    <>
      <button className="btn btn-primary btn-sm" type="button" onClick={() => showModal()}>
        Details
      </button>

      <BootstrapModal ref={modalRef} title={`Whois ${address}`}>
        <WhoisText address={address} />
      </BootstrapModal>
    </>
  )
}

export function WhoisCountry({ address }: { address: string }) {
  if (isUnknown(address)) return <>Unknown</>

  const { record, loading } = useWhois(address)
  if (!record || loading) return <Loading />

  return <>{record.countries.join(", ")}</>
}

export function WhoisOrganization({ address }: { address: string }) {
  if (isUnknown(address)) return <>Unknown</>

  const { record, loading } = useWhois(address)
  if (!record || loading) return <Loading />

  return <>{record.organizations.join(", ")}</>
}

export function WhoisText({ address }: { address: string }) {
  if (isUnknown(address))
    return (
      <pre>
        <code>No information for "{address}".</code>
        <br />
      </pre>
    )

  const { record, loading } = useWhois(address)
  if (!record || loading) return <Loading />

  return (
    <pre>
      {record.error ? (
        <code>{record.error}</code>
      ) : (
        record.parts.map(({ body, host }) => (
          <Fragment key={host}>
            <code>{body}</code>
            <br />
          </Fragment>
        ))
      )}
    </pre>
  )
}

function isUnknown(address: string) {
  return !address || address === "Unknown"
}

interface WhoisResponse {
  countries: string[]
  organizations: string[]
  parts: { host: string; body: string }[]
  error?: string
}

function useWhois(address: string) {
  const { data, loading, error } = useCachedResource<WhoisResponse>(`/admin/whois?${new URLSearchParams({ address })}`)

  if (error) throw error

  return { record: data, loading }
}
