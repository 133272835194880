/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BackupServer
// ====================================================

export interface BackupServer_serverBackup_node_Image {
  __typename: "Image" | "Key" | "Provider" | "Region" | "SizeCategory" | "Size";
}

export interface BackupServer_serverBackup_node_Server {
  __typename: "Server";
  id: string;
  name: string;
  pending: boolean;
  power: boolean;
  costSoFar: number;
  status: string;
  backupsEnabled: boolean;
  message: string;
  ipAddress: string | null;
  privateIpAddress: string | null;
  ipAddressV6: string | null;
  privateIpAddressV6: string | null;
  providerId: string | null;
}

export type BackupServer_serverBackup_node = BackupServer_serverBackup_node_Image | BackupServer_serverBackup_node_Server;

export interface BackupServer_serverBackup_errors {
  __typename: "Error";
  field: string;
  codes: string[];
}

export interface BackupServer_serverBackup {
  __typename: "ServerBackupPayload";
  node: BackupServer_serverBackup_node | null;
  errors: BackupServer_serverBackup_errors[];
}

export interface BackupServer {
  serverBackup: BackupServer_serverBackup | null;
}

export interface BackupServerVariables {
  input: ServerBackupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateServer
// ====================================================

export interface CreateServer_serverCreate_node_Image {
  __typename: "Image" | "Key" | "Provider" | "Region" | "SizeCategory" | "Size";
}

export interface CreateServer_serverCreate_node_Server {
  __typename: "Server";
  id: string;
  name: string;
  pending: boolean;
  power: boolean;
  costSoFar: number;
  status: string;
  backupsEnabled: boolean;
  message: string;
  ipAddress: string | null;
  privateIpAddress: string | null;
  ipAddressV6: string | null;
  privateIpAddressV6: string | null;
  providerId: string | null;
}

export type CreateServer_serverCreate_node = CreateServer_serverCreate_node_Image | CreateServer_serverCreate_node_Server;

export interface CreateServer_serverCreate_errors {
  __typename: "Error";
  field: string;
  codes: string[];
}

export interface CreateServer_serverCreate {
  __typename: "ServerCreatePayload";
  node: CreateServer_serverCreate_node | null;
  errors: CreateServer_serverCreate_errors[];
}

export interface CreateServer {
  serverCreate: CreateServer_serverCreate | null;
}

export interface CreateServerVariables {
  input: ServerCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProviders
// ====================================================

export interface GetProviders_providers {
  __typename: "Provider";
  id: string;
  name: string;
  logoUrl: string;
}

export interface GetProviders {
  providers: GetProviders_providers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteServer
// ====================================================

export interface DeleteServer_serverDelete_node_Image {
  __typename: "Image" | "Key" | "Provider" | "Region" | "SizeCategory" | "Size";
}

export interface DeleteServer_serverDelete_node_Server {
  __typename: "Server";
  id: string;
  name: string;
  pending: boolean;
  power: boolean;
  costSoFar: number;
  status: string;
  backupsEnabled: boolean;
  message: string;
  ipAddress: string | null;
  privateIpAddress: string | null;
  ipAddressV6: string | null;
  privateIpAddressV6: string | null;
  providerId: string | null;
}

export type DeleteServer_serverDelete_node = DeleteServer_serverDelete_node_Image | DeleteServer_serverDelete_node_Server;

export interface DeleteServer_serverDelete_errors {
  __typename: "Error";
  field: string;
  codes: string[];
}

export interface DeleteServer_serverDelete {
  __typename: "ServerDeletePayload";
  node: DeleteServer_serverDelete_node | null;
  errors: DeleteServer_serverDelete_errors[];
}

export interface DeleteServer {
  serverDelete: DeleteServer_serverDelete | null;
}

export interface DeleteServerVariables {
  input: ServerDeleteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetImage
// ====================================================

export interface GetImage_image {
  __typename: "Image";
  id: string;
  name: string;
  distribution: string;
  windows: boolean;
  architecture: ImageArchitecture;
}

export interface GetImage {
  image: GetImage_image | null;
}

export interface GetImageVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetImages
// ====================================================

export interface GetImages_images {
  __typename: "Image";
  id: string;
  name: string;
  distribution: string;
  windows: boolean;
  architecture: ImageArchitecture;
}

export interface GetImages {
  images: GetImages_images[];
}

export interface GetImagesVariables {
  providerId: string;
  type?: ImageKind | null;
  architecture?: ImageArchitecture | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetKeys
// ====================================================

export interface GetKeys_keys {
  __typename: "Key";
  id: string;
  key: string;
  label: string;
}

export interface GetKeys {
  keys: GetKeys_keys[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ClearServerMessage
// ====================================================

export interface ClearServerMessage_serverClearMessage_node_Image {
  __typename: "Image" | "Key" | "Provider" | "Region" | "SizeCategory" | "Size";
}

export interface ClearServerMessage_serverClearMessage_node_Server {
  __typename: "Server";
  id: string;
  name: string;
  pending: boolean;
  power: boolean;
  costSoFar: number;
  status: string;
  backupsEnabled: boolean;
  message: string;
  ipAddress: string | null;
  privateIpAddress: string | null;
  ipAddressV6: string | null;
  privateIpAddressV6: string | null;
  providerId: string | null;
}

export type ClearServerMessage_serverClearMessage_node = ClearServerMessage_serverClearMessage_node_Image | ClearServerMessage_serverClearMessage_node_Server;

export interface ClearServerMessage_serverClearMessage_errors {
  __typename: "Error";
  field: string;
  codes: string[];
}

export interface ClearServerMessage_serverClearMessage {
  __typename: "ServerClearMessagePayload";
  node: ClearServerMessage_serverClearMessage_node | null;
  errors: ClearServerMessage_serverClearMessage_errors[];
}

export interface ClearServerMessage {
  serverClearMessage: ClearServerMessage_serverClearMessage | null;
}

export interface ClearServerMessageVariables {
  input: ServerClearMessageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PowerServer
// ====================================================

export interface PowerServer_serverPower_node_Image {
  __typename: "Image" | "Key" | "Provider" | "Region" | "SizeCategory" | "Size";
}

export interface PowerServer_serverPower_node_Server {
  __typename: "Server";
  id: string;
  name: string;
  pending: boolean;
  power: boolean;
  costSoFar: number;
  status: string;
  backupsEnabled: boolean;
  message: string;
  ipAddress: string | null;
  privateIpAddress: string | null;
  ipAddressV6: string | null;
  privateIpAddressV6: string | null;
  providerId: string | null;
}

export type PowerServer_serverPower_node = PowerServer_serverPower_node_Image | PowerServer_serverPower_node_Server;

export interface PowerServer_serverPower_errors {
  __typename: "Error";
  field: string;
  codes: string[];
}

export interface PowerServer_serverPower {
  __typename: "ServerPowerPayload";
  node: PowerServer_serverPower_node | null;
  errors: PowerServer_serverPower_errors[];
}

export interface PowerServer {
  serverPower: PowerServer_serverPower | null;
}

export interface PowerServerVariables {
  input: ServerPowerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RebuildServer
// ====================================================

export interface RebuildServer_serverRebuild_node_Image {
  __typename: "Image" | "Key" | "Provider" | "Region" | "SizeCategory" | "Size";
}

export interface RebuildServer_serverRebuild_node_Server {
  __typename: "Server";
  id: string;
  name: string;
  pending: boolean;
  power: boolean;
  costSoFar: number;
  status: string;
  backupsEnabled: boolean;
  message: string;
  ipAddress: string | null;
  privateIpAddress: string | null;
  ipAddressV6: string | null;
  privateIpAddressV6: string | null;
  providerId: string | null;
}

export type RebuildServer_serverRebuild_node = RebuildServer_serverRebuild_node_Image | RebuildServer_serverRebuild_node_Server;

export interface RebuildServer_serverRebuild_errors {
  __typename: "Error";
  field: string;
  codes: string[];
}

export interface RebuildServer_serverRebuild {
  __typename: "ServerRebuildPayload";
  node: RebuildServer_serverRebuild_node | null;
  errors: RebuildServer_serverRebuild_errors[];
}

export interface RebuildServer {
  serverRebuild: RebuildServer_serverRebuild | null;
}

export interface RebuildServerVariables {
  input: ServerRebuildInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRegions
// ====================================================

export interface GetRegions_regions {
  __typename: "Region";
  id: string;
  name: string;
  slug: string;
  origin: string;
  number: number;
  availableSizeSlugs: string[];
}

export interface GetRegions {
  regions: GetRegions_regions[];
}

export interface GetRegionsVariables {
  providerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResizeServer
// ====================================================

export interface ResizeServer_serverResize_node_Image {
  __typename: "Image" | "Key" | "Provider" | "Region" | "SizeCategory" | "Size";
}

export interface ResizeServer_serverResize_node_Server {
  __typename: "Server";
  id: string;
  name: string;
  pending: boolean;
  power: boolean;
  costSoFar: number;
  status: string;
  backupsEnabled: boolean;
  message: string;
  ipAddress: string | null;
  privateIpAddress: string | null;
  ipAddressV6: string | null;
  privateIpAddressV6: string | null;
  providerId: string | null;
}

export type ResizeServer_serverResize_node = ResizeServer_serverResize_node_Image | ResizeServer_serverResize_node_Server;

export interface ResizeServer_serverResize_errors {
  __typename: "Error";
  field: string;
  codes: string[];
}

export interface ResizeServer_serverResize {
  __typename: "ServerResizePayload";
  node: ResizeServer_serverResize_node | null;
  errors: ResizeServer_serverResize_errors[];
}

export interface ResizeServer {
  serverResize: ResizeServer_serverResize | null;
}

export interface ResizeServerVariables {
  input: ServerResizeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSizeCategories
// ====================================================

export interface GetSizeCategories_sizeCategories_subcategories_sizes {
  __typename: "Size";
  id: string;
  name: string;
  slug: string;
  price: number;
  pricePerHour: number;
  windowsFee: number;
  memory: string;
  processor: string;
  bandwidth: number;
  disk: string;
  kind: string;
}

export interface GetSizeCategories_sizeCategories_subcategories {
  __typename: "SizeCategory";
  id: string;
  name: string;
  description: string | null;
  architecture: ImageArchitecture;
  sizes: GetSizeCategories_sizeCategories_subcategories_sizes[];
}

export interface GetSizeCategories_sizeCategories {
  __typename: "SizeCategory";
  id: string;
  name: string;
  description: string | null;
  architecture: ImageArchitecture;
  subcategories: GetSizeCategories_sizeCategories_subcategories[];
}

export interface GetSizeCategories {
  sizeCategories: GetSizeCategories_sizeCategories[];
}

export interface GetSizeCategoriesVariables {
  providerId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSizes
// ====================================================

export interface GetSizes_sizes {
  __typename: "Size";
  id: string;
  name: string;
  slug: string;
  price: number;
  pricePerHour: number;
  windowsFee: number;
  memory: string;
  processor: string;
  bandwidth: number;
  disk: string;
  kind: string;
}

export interface GetSizes {
  sizes: GetSizes_sizes[];
}

export interface GetSizesVariables {
  providerId?: string | null;
  serverId?: string | null;
  regionId?: string | null;
  categoryId?: string | null;
  kind?: string | null;
  permanent?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SnapshotServer
// ====================================================

export interface SnapshotServer_serverSnapshot_node_Image {
  __typename: "Image" | "Key" | "Provider" | "Region" | "SizeCategory" | "Size";
}

export interface SnapshotServer_serverSnapshot_node_Server {
  __typename: "Server";
  id: string;
  name: string;
  pending: boolean;
  power: boolean;
  costSoFar: number;
  status: string;
  backupsEnabled: boolean;
  message: string;
  ipAddress: string | null;
  privateIpAddress: string | null;
  ipAddressV6: string | null;
  privateIpAddressV6: string | null;
  providerId: string | null;
}

export type SnapshotServer_serverSnapshot_node = SnapshotServer_serverSnapshot_node_Image | SnapshotServer_serverSnapshot_node_Server;

export interface SnapshotServer_serverSnapshot_errors {
  __typename: "Error";
  field: string;
  codes: string[];
}

export interface SnapshotServer_serverSnapshot {
  __typename: "ServerSnapshotPayload";
  node: SnapshotServer_serverSnapshot_node | null;
  errors: SnapshotServer_serverSnapshot_errors[];
}

export interface SnapshotServer {
  serverSnapshot: SnapshotServer_serverSnapshot | null;
}

export interface SnapshotServerVariables {
  input: ServerSnapshotInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Error
// ====================================================

export interface Error {
  __typename: "Error";
  field: string;
  codes: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Image
// ====================================================

export interface Image {
  __typename: "Image";
  id: string;
  name: string;
  distribution: string;
  windows: boolean;
  architecture: ImageArchitecture;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Key
// ====================================================

export interface Key {
  __typename: "Key";
  id: string;
  key: string;
  label: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Provider
// ====================================================

export interface Provider {
  __typename: "Provider";
  id: string;
  name: string;
  logoUrl: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Server
// ====================================================

export interface Server {
  __typename: "Server";
  id: string;
  name: string;
  pending: boolean;
  power: boolean;
  costSoFar: number;
  status: string;
  backupsEnabled: boolean;
  message: string;
  ipAddress: string | null;
  privateIpAddress: string | null;
  ipAddressV6: string | null;
  privateIpAddressV6: string | null;
  providerId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Size
// ====================================================

export interface Size {
  __typename: "Size";
  id: string;
  name: string;
  slug: string;
  price: number;
  pricePerHour: number;
  windowsFee: number;
  memory: string;
  processor: string;
  bandwidth: number;
  disk: string;
  kind: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Region
// ====================================================

export interface Region {
  __typename: "Region";
  id: string;
  name: string;
  slug: string;
  origin: string;
  number: number;
  availableSizeSlugs: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ImageArchitecture {
  ARM = "ARM",
  X86 = "X86",
}

export enum ImageKind {
  CUSTOM = "CUSTOM",
  SNAPSHOT = "SNAPSHOT",
  STANDARD = "STANDARD",
}

export enum Power {
  OFF = "OFF",
  ON = "ON",
  REBOOT = "REBOOT",
}

/**
 * Autogenerated input type of ServerBackup
 */
export interface ServerBackupInput {
  automatic: boolean;
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of ServerClearMessage
 */
export interface ServerClearMessageInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of ServerCreate
 */
export interface ServerCreateInput {
  backupsEnabled: boolean;
  clientMutationId?: string | null;
  imageId: string;
  keyIds: string[];
  name: string;
  providerId: string;
  regionId: string;
  sizeId: string;
  terms: boolean;
}

/**
 * Autogenerated input type of ServerDelete
 */
export interface ServerDeleteInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of ServerPower
 */
export interface ServerPowerInput {
  action: Power;
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of ServerRebuild
 */
export interface ServerRebuildInput {
  clientMutationId?: string | null;
  id: string;
  imageId: string;
}

/**
 * Autogenerated input type of ServerResize
 */
export interface ServerResizeInput {
  clientMutationId?: string | null;
  id: string;
  permanent?: boolean | null;
  sizeId: string;
}

/**
 * Autogenerated input type of ServerSnapshot
 */
export interface ServerSnapshotInput {
  clientMutationId?: string | null;
  id: string;
  name: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
