import * as Sentry from "@sentry/browser"
import {
  Channels,
  DOMComponents,
  DOMMobileNavigation,
  Modal,
  PopOver,
  Copy,
  Redirection,
  Toggle,
  IntercomLayout,
  HCaptcha
} from "./next/delegates"

Sentry.init({
  dsn: "https://91a3a12b46614b379c832653fbb8d71c@sentry.io/198687",
  allowUrls: [/https?:\/\/((dashboard|staging)\.)?bithost\.io/],
  ignoreErrors: [
    "NS_ERROR_NOT_INITIALIZED",
    "window.Intercom is not a function",
    "NetworkError when attempting to fetch resource."
  ]
})

require("turbolinks").start()

try {
  require("@rails/ujs").start()
} catch {
  // don't care about double initialization.
}

const Next = {
  initialize() {
    Copy.initialize()
    DOMComponents.initialize()
    DOMMobileNavigation.initialize()
    Modal.initialize()
    HCaptcha.initialize()
    PopOver.initialize()
    Channels.initialize()
    Redirection.initialize()
    Toggle.initialize()
    IntercomLayout.initialize()
  },

  finalize() {
    Copy.finalize?.()
    Modal.finalize?.()
    PopOver.finalize?.()
    IntercomLayout.finalize?.()
  }
}

document.addEventListener("turbolinks:load", Next.initialize)
document.addEventListener("turbolinks:before-cache", Next.finalize)
