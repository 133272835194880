import { useCachedResource } from "next/utils"
import React from "react"
import { BootstrapModal, useBootstrapModal, WhoisText } from "."
import { Loading } from ".."

export function FraudDetectionButton({ address }: { address: string }) {
  const { modalRef, modalShown, showModal } = useBootstrapModal()

  return (
    <>
      <button className="btn btn-primary btn-sm" type="button" onClick={() => showModal()}>
        Suspected users
      </button>

      {modalShown && (
        <BootstrapModal ref={modalRef} title={`Users with ${address}`}>
          <FraudulentUsersTable ip={address} />
          <div className="card mt-3">
            <div className="card-header">Whois {address}</div>
            <div className="card-body">
              <WhoisText address={address} />
            </div>
          </div>
        </BootstrapModal>
      )}
    </>
  )
}

export function FraudulentUsersTable({ ip, id }: { ip?: string; id?: number }) {
  const { users, loading } = useFraudelentUsers({ ip, id })

  if (!users || loading) return <Loading />

  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Id</th>
            <th>Email</th>
            <th>Signup Ip</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id} className={user.banned ? "table-danger" : ""}>
              <td>{user.id}</td>
              <td>{user.email}</td>
              <td>{user.signup_ip_address || "–"}</td>
              <td>
                <a
                  className="btn btn-primary btn-sm"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/admin/users/${user.id}`}
                >
                  Details
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

interface UserResponse {
  id: number
  email: string
  signup_ip_address: string
  banned: boolean
}

function useFraudelentUsers({ ip, id }: { ip?: string; id?: number }) {
  const { data, loading, error } = useCachedResource<UserResponse[]>(
    ip
      ? `/admin/fraud_detection/users?${new URLSearchParams({ ip })}`
      : `/admin/fraud_detection/users?${new URLSearchParams({ id: `${id}` })}`
  )

  if (error) throw error

  return { users: data, loading }
}
