import React, { useState } from "react"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import { ImagesSelect, Progress } from "."
import { DangerousOperation, Controls, Tooltip } from ".."
import { RebuildServer, RebuildServerVariables, ERROR_FRAGMENT, SERVER_FRAGMENT } from "../../graph"

const REBUILD_SERVER = gql`
  mutation RebuildServer($input: ServerRebuildInput!) {
    serverRebuild(input: $input) {
      node {
        ...Server
      }
      errors {
        ...Error
      }
    }
  }

  ${SERVER_FRAGMENT}
  ${ERROR_FRAGMENT}
`

export function RebuildForm({ id, providerId, pending }: { id: string; providerId: string; pending: boolean }) {
  const [imageId, setImageId] = useState("")
  const [progress, setProgress] = useState(pending)
  const [rebuildServer] = useMutation<RebuildServer, RebuildServerVariables>(REBUILD_SERVER)

  const submitHandler = async (event: React.FormEvent, close: Function) => {
    event.preventDefault()

    await rebuildServer({
      variables: {
        input: { id, imageId }
      }
    })

    setProgress(true)
    close()
  }

  const rebuildTriggerHandler = (event: React.FormEvent, open: Function) => {
    event.preventDefault()

    if (imageId) open()
  }

  const tooltipContent = !imageId ? "Select an image" : "Server is busy"

  return (
    <DangerousOperation
      warning="All server data will be permanently lost..."
      confirm={() => (
        <label htmlFor="rebuild-submit">
          <span className="btn-danger">Continue</span>
        </label>
      )}
    >
      {({ open, close }: Controls) => (
        <form id="rebuild" onSubmit={event => submitHandler(event, close)}>
          {progress && <Progress id={id} />}

          <ImagesSelect className="spacing-bottom" providerId={providerId} onChange={setImageId} />

          <div className="button-wrapper">
            <Tooltip content={tooltipContent} disabled={!!imageId && !progress}>
              <button
                type="button"
                className="btn-danger"
                disabled={!imageId || progress}
                onClick={event => rebuildTriggerHandler(event, open)}
              >
                Rebuild
              </button>
            </Tooltip>
          </div>

          <input id="rebuild-submit" className="hidden" type="submit" />
        </form>
      )}
    </DangerousOperation>
  )
}
