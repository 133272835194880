import { gql, useQuery } from "@apollo/client"
import { Size, IMAGE_FRAGMENT, GetImage, GetImageVariables } from "next/graph"
import { formatSize, formatUSD, VultrProvider } from "next/models"
import { assert } from "next/utils"
import React from "react"

const GET_IMAGE = gql`
  query GetImage($id: ID!) {
    image(id: $id) {
      ...Image
    }
  }

  ${IMAGE_FRAGMENT}
`

export function EstimatedCost({
  size,
  imageId,
  providerId,
  backupsEnabled
}: {
  size: Size | null
  imageId: string
  providerId: string
  backupsEnabled: boolean
}) {
  const { data, loading, error } = useQuery<GetImage, GetImageVariables>(GET_IMAGE, {
    variables: { id: imageId }
  })

  assert(!error, `Error fetching image ${error?.message}`, { imageId, error: error?.name })

  const sizeCost = size?.price ?? 0
  const backupsCost = backupsEnabled ? 0.2 * sizeCost : 0
  const windowsCost = data?.image?.windows ? size?.windowsFee ?? 0 : 0

  const isWindowsOnVultr = providerId === VultrProvider && data?.image?.windows

  return (
    <div className="estimated-cost">
      <label htmlFor="" className="control-label">
        Estimated cost
      </label>

      <div className="table-wrapper">
        <table>
          <tbody>
            <tr>
              <th>{size && formatSize(size, "detail")}</th>
              <td className="number">{size ? formatUSD(size.price) : formatUSD(0)}/mo</td>
            </tr>
            {backupsEnabled && sizeCost !== 0 && (
              <tr>
                <th>Automated Backups</th>
                <td className="number">{formatUSD(backupsCost)}/mo</td>
              </tr>
            )}
            {isWindowsOnVultr && sizeCost !== 0 && (
              <tr>
                <th>Windows License Fee</th>
                <td className="number">{loading ? "..." : `${formatUSD(windowsCost)}/mo`}</td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th />
              <td className="number">
                <strong>{formatUSD(sizeCost + backupsCost + windowsCost)}</strong>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}
