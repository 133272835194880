import { useEffect } from "react"
import scrollIntoView from "scroll-into-view-if-needed"
import tippy from "tippy.js"
import { ServerEventChannel } from "../../channels"

export function Progress({ id }: { id: string }) {
  useEffect(() => {
    // Ensures only one progress element is rendered no matter how many times
    // the Progress component is in the React render tree.
    const progressElement = document.querySelector(".progress")
    if (progressElement) {
      // Scroll into the view only if we don't currently have a spinner.
      if (progressElement.children.length === 0) {
        scrollIntoView(progressElement, {
          behavior: "smooth",
          scrollMode: "if-needed"
        })
      }

      progressElement.innerHTML = `
        <div class="spinner-border" role="status"></div>
      `

      tippy(".progress", {
        content: "Server is busy."
      })
    }
  })

  useEffect(() => {
    ServerEventChannel.await(id)
  }, [id])

  return null
}
