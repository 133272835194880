declare global {
  interface Window {
    hCaptchaSubmit: Function
  }
}

export const HCaptcha = {
  initialize() {
    window.hCaptchaSubmit = hCaptchaSubmit
  }
}

function hCaptchaSubmit() {
  document.querySelector("form")?.submit()
}
