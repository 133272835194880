import { useMutation, useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { classnames } from "next/utils"
import React, { useCallback, useState } from "react"
import {
  AutomaticBackups,
  EstimatedCost,
  ImagesSelect,
  KeysSelect,
  RegionsSelect,
  SizeSelect,
  SizeCategorySelect
} from "."
import { Alert, Controls, DangerousOperation, Fieldset, Loading, PageTitle, Tooltip } from ".."
import { ServerEventChannel } from "../../channels"
import {
  CreateServer,
  CreateServerVariables,
  ERROR_FRAGMENT,
  GetProviders,
  ImageArchitecture,
  ImageKind,
  Provider,
  PROVIDER_FRAGMENT,
  Server,
  SERVER_FRAGMENT,
  Size
} from "../../graph"
import { DigitalOceanProvider, isARMSizeKind, SizeKind, VultrProvider } from "../../models"
import * as route from "../../route"

const CREATE_SERVER = gql`
  mutation CreateServer($input: ServerCreateInput!) {
    serverCreate(input: $input) {
      node {
        ...Server
      }
      errors {
        ...Error
      }
    }
  }

  ${SERVER_FRAGMENT}
  ${ERROR_FRAGMENT}
`

interface FormState {
  name: string
  providerId: string
  sizeId: string
  imageId: string
  regionId: string
  keyIds: string[]
  backupsEnabled: boolean
  terms: boolean
}

export function CreateForm({ providerId, imageId = "" }: { providerId: string; imageId: string }) {
  const [size, setSize] = useState<Size | null>(null)
  const [availableSizeSlugs, setAvailableSizeSlugs] = useState<string[]>([])
  const [sizeKind, setSizeKind] = useState<SizeKind | undefined>(undefined)
  const [state, setState] = useState<FormState>(() => ({
    providerId,
    imageId,
    sizeId: "",
    regionId: "",
    name: "",
    keyIds: [],
    backupsEnabled: false,
    terms: true
  }))
  const [errors, setErrors] = useState<Record<string, string[]>>({})
  const [createServer, { loading }] = useMutation<CreateServer, CreateServerVariables>(CREATE_SERVER)

  const isBackupRestore = providerId && imageId
  const isARM = isARMSizeKind(sizeKind)

  const handleSubmitTrigger = (event: React.SyntheticEvent, open: Controls["open"]) => {
    event.preventDefault()

    open()
  }

  const handleSubmit = async (close: Controls["close"]) => {
    const response = await createServer({ variables: { input: state } })

    close()

    if (response.data?.serverCreate?.errors?.length) {
      const validationErrors: Record<string, string[]> = {}

      response.data!.serverCreate!.errors.forEach(error => {
        validationErrors[error.field] = error.codes
      })

      setErrors(validationErrors)
    } else {
      const { id } = response.data!.serverCreate!.node as Server

      ServerEventChannel.await(id)
      route.visit(id)
    }
  }

  const canSubmit = useCallback(
    () => !!(state.name && state.sizeId && state.imageId && state.regionId && state.keyIds.length > 0),
    [state]
  )

  const requiredState = (field: keyof FormState, message: string) => {
    const value = state[field]

    if (typeof value === "string") {
      if (value.trim().length === 0) return message
    } else if (typeof value === "boolean") {
      if (!value) return message
    } else if (value.length === 0) {
      return message
    }
  }

  const tooltipContent = (
    <ul>
      {[
        requiredState("regionId", "Select a region"),
        requiredState("sizeId", "Select a size"),
        requiredState("imageId", "Select an image"),
        requiredState("keyIds", "Select at least one SSH key"),
        requiredState("name", "Set a server name")
      ]
        .filter(Boolean)
        .map(message => (
          <li key={message}>{message}</li>
        ))}
    </ul>
  )

  return (
    <div className="server-create">
      <PageTitle
        className="spacing-bottom"
        title="Create server"
        renderLeft={() => (
          <a href={route.servers()}>
            <i className="icon chevron-left" />
          </a>
        )}
      />

      <Fieldset className="spacing-bottom-lg" field="Provider">
        <ProvidersSelect
          selected={state.providerId}
          className="spacing-bottom"
          onChange={(id: string) => {
            setSize(null)
            setSizeKind(undefined)
            setState(pending => ({ ...pending, imageId, sizeId: "", regionId: "", providerId: id }))
          }}
          onlyProviderId={isBackupRestore ? providerId : undefined}
        />
      </Fieldset>

      <Fieldset
        className="spacing-bottom-lg"
        field="Region"
        error={requiredState("regionId", "Please select a region")}
      >
        <RegionsSelect
          key={state.providerId}
          providerId={state.providerId}
          onSelect={region => {
            setState(prev => ({ ...prev, regionId: region.id, sizeId: "" }))
            setAvailableSizeSlugs(region.availableSizeSlugs)
          }}
        />
      </Fieldset>

      <Fieldset className="spacing-bottom-lg" field="Size" error={requiredState("sizeId", "Please select a size")}>
        <SizeCategorySelect
          key={state.providerId}
          providerId={state.providerId}
          selected={state.sizeId}
          onChange={selectedSize => {
            setSize(selectedSize)
            setState(prev => ({ ...prev, sizeId: selectedSize.id }))
          }}
          availableSizeSlugs={availableSizeSlugs}
        />
      </Fieldset>

      <Fieldset className="spacing-bottom-lg" field="Image" error={requiredState("imageId", "Please select an image")}>
        <ImagesSelect
          key={`${state.providerId}-${isARM}`}
          providerId={state.providerId}
          imageId={state.imageId}
          architecture={isARM ? ImageArchitecture.ARM : ImageArchitecture.X86}
          kind={state.imageId ? ImageKind.SNAPSHOT : ImageKind.STANDARD}
          theme="tabs"
          onChange={id => setState(prev => ({ ...prev, imageId: id }))}
        />
      </Fieldset>

      <Fieldset
        className="spacing-bottom-lg"
        field="Access"
        error={requiredState("keyIds", "Please select at least one SSH key")}
      >
        <h4>SSH Keys</h4>

        <KeysSelect onSelect={keyIds => setState(prev => ({ ...prev, keyIds }))} />
      </Fieldset>

      <Fieldset className="spacing-bottom-lg" field="Details" error={requiredState("name", "Please set a server name")}>
        <div className="form-group">
          <label className="control-label" htmlFor="name">
            Server name
          </label>
          <input
            type="text"
            className="form-control"
            value={state.name}
            placeholder="Enter your server name..."
            onChange={(event: React.SyntheticEvent) => {
              event.persist()

              setState(prev => ({ ...prev, name: (event.target as any).value }))
            }}
          />
        </div>

        <CreationError errors={errors.name} />

        <br />

        <AutomaticBackups
          value={state.backupsEnabled}
          onChange={() => setState(prev => ({ ...prev, backupsEnabled: !prev.backupsEnabled }))}
        />
      </Fieldset>

      {size != null && (
        <Fieldset className="spacing-bottom-lg" field="Summary">
          <EstimatedCost
            size={size}
            imageId={state.imageId}
            providerId={state.providerId}
            backupsEnabled={state.backupsEnabled}
          />
        </Fieldset>
      )}

      {(errors.user || errors.server || errors.base) && (
        <Fieldset className="spacing-bottom-lg" field="Errors">
          <CreationError errors={errors.user || errors.server || errors.base} />
        </Fieldset>
      )}

      <Fieldset>
        <DangerousOperation
          title="Terms of Service"
          warning={
            <>
              By creating this server, you accept that if your account runs out of funds, your server(s) will be removed
              and no backups will be kept. It is your responsibly to make sure your account is funded! Please read and
              accept our{" "}
              <a href="https://bithost.io/terms" target="_blank" rel="noopener noreferrer">
                terms of service
              </a>{" "}
              before creating this server.
            </>
          }
          confirm={({ close }: Controls) => (
            <button onClick={() => handleSubmit(close)} disabled={loading} type="button" className="btn-danger">
              Accept
            </button>
          )}
          size="50%"
        >
          {({ open }: Controls) => (
            <div className="actions spacing-bottom">
              <Tooltip content={tooltipContent} disabled={canSubmit()}>
                <button
                  type="button"
                  className="btn-success lg"
                  disabled={!canSubmit() || loading}
                  onClick={event => handleSubmitTrigger(event, open)}
                >
                  Create server
                </button>
              </Tooltip>
            </div>
          )}
        </DangerousOperation>
      </Fieldset>
    </div>
  )
}

const creationErrorsCodeFormat = (code: string) => {
  switch (code) {
    case "quota_exceeded":
      return "Your server quota has been exceeded."
    case "unconfirmed":
      return "Your user is not confirmed. Check your email and confirm your account."
    case "invalid_snapshot":
      return "Invalid snapshot selected."
    case "invalid":
      return "Must only contain alphanumeric characters, dashes, and periods."
    case "no_keys":
      return "You have to have at least one SSH key to create a server."
    case "disabled":
      return "The image no-longer exists."
    case "insufficient_funds":
      return "You don't have sufficient funds to create a server and keep it running five days, please top up."
    default:
      return `Something went wrong! Please contact support if the error persists. Code: ${code}.`
  }
}

function CreationError({
  errors,
  format = creationErrorsCodeFormat
}: {
  errors: string[] | undefined
  format?: (code: string) => string
}) {
  if (!errors || !errors.length) {
    return null
  }

  return (
    <div className="errors">
      {errors.map(code => (
        <Alert type="error">{format(code)}</Alert>
      ))}
    </div>
  )
}

const GET_PROVIDERS = gql`
  query GetProviders {
    providers {
      ...Provider
    }
  }

  ${PROVIDER_FRAGMENT}
`

function ProvidersSelect({
  onChange,
  selected = "",
  className = "",
  onlyProviderId = undefined
}: {
  onChange: (id: string) => any
  selected: string
  className?: string
  onlyProviderId?: string
}) {
  const { data, loading, error } = useQuery<GetProviders>(GET_PROVIDERS)

  if (error) throw error
  if (loading || !data) {
    return <Loading />
  }

  return (
    <div className={`providers-select ${className}`}>
      <div className="segments-alt">
        {data.providers.map((provider: Provider) => (
          <a
            className={classnames(
              onlyProviderId && provider.id !== onlyProviderId && "disabled",
              provider.id === selected && "active"
            )}
            key={provider.id}
            onClick={event => {
              event.preventDefault()

              if (!onlyProviderId) onChange(provider.id)
            }}
          >
            <img src={provider.logoUrl} alt="Logo" />
          </a>
        ))}
      </div>
    </div>
  )
}
