import React from "react"

export function Tabs<T = string>({
  items,
  onChange,
  active,
  format = item => `${item}`
}: {
  items: readonly T[]
  active: T
  onChange: (tab: T) => void
  format?: (item: T) => string
}) {
  return (
    <div className="tabs-wrapper">
      <nav className="tabs">
        {items.map(item => (
          <a
            key={format(item)}
            href="#"
            className={`${active === item ? "active" : ""}`}
            onClick={event => {
              event.preventDefault()
              if (onChange) onChange(item)
            }}
          >
            {format(item)}
          </a>
        ))}
      </nav>
    </div>
  )
}
