import React from "react"
import * as Sentry from "@sentry/browser"

export class SentryErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true }
  }

  state: { eventId: string | undefined; hasError: boolean } = {
    eventId: undefined,
    hasError: false
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    const { hasError, eventId } = this.state
    const { children } = this.props

    if (hasError) {
      return (
        <button type="button" onClick={() => Sentry.showReportDialog({ eventId })}>
          Report feedback
        </button>
      )
    }

    return children
  }
}
