import React from "react"
import * as ReactDOM from "react-dom"
import { LinkModal, OpenModal } from "../components"

export const Modal = {
  remoteCleanup: null as Function | null,

  initialize() {
    initializeInline()
    this.remoteCleanup = initializeRemote()
  },

  finalize() {
    const internals = document.querySelectorAll(".js-modal-internal")

    internals.forEach(internal => {
      internal.remove()
    })

    this.remoteCleanup?.()
  }
}

function initializeInline() {
  const modals = document.querySelectorAll<HTMLAnchorElement>('a.js-modal-open[href="#"]')

  modals.forEach(modal => {
    modal.addEventListener("click", event => {
      event.preventDefault()

      const { size, title, selector } = modal.dataset
      const content = document.querySelector(selector!)

      const element = document.createElement("div")
      element.classList.add("js-modal-internal")
      document.body.appendChild(element)

      ReactDOM.render(
        <OpenModal title={title} content={content?.innerHTML} size={size} onClose={() => element.remove()} />,
        element
      )
    })
  })
}

function initializeRemote() {
  const modals = document.querySelectorAll<HTMLAnchorElement>('a.js-modal-open:not([href="#"])')

  type MouseEventHandler = (event: MouseEvent) => void
  const clickHandlers: MouseEventHandler[] = []

  modals.forEach(modal => {
    const clickHandler = (event: MouseEvent) => {
      event.preventDefault()

      const { size, title } = modal.dataset

      const element = document.createElement("div")
      element.classList.add("js-modal-internal")
      document.body.appendChild(element)

      ReactDOM.render(
        <LinkModal title={title} href={modal.href} size={size} onClose={() => element.remove()} />,
        element
      )
    }

    modal.addEventListener("click", clickHandler)
    clickHandlers.push(clickHandler)
  })

  return () => {
    modals.forEach((modal, i) => {
      modal.removeEventListener("click", clickHandlers[i])
    })
  }
}
