import htm from "htm"
import React from "react"
import { createRoot } from "react-dom/client"
import * as components from "../components"
import { SentryErrorBoundary } from "../components"
import _ from "../utils/_"

const html = htm.bind(React.createElement)

export const AdminComponents = {
  initialize(target: { querySelectorAll: Element["querySelectorAll"] } = document) {
    target.querySelectorAll("[data-component]").forEach(c => {
      const data = (c as HTMLElement).dataset
      const component = _.get(components, data.component!)
      const props = JSON.parse(data.props!)

      const root = createRoot(c)
      root.render(<Wrapper component={component} props={props} />)
    })

    target.querySelectorAll<HTMLElement>("[data-htm]").forEach(element => {
      // Destructure the components default import into a short local variable
      // name we know won't be mungled during minification because it's used
      // in eval.
      const { ...c } = components

      // eslint-disable-next-line no-new-func
      const jsx = new Function(
        "html",
        "c",
        `
          with (c) {
            return html\`
              ${element.dataset.htm}
            \`
          }
        `
      )(html, c)

      const root = createRoot(element)
      root.render(<Wrapper jsx={jsx} />)
    })
  }
}

function Wrapper(input: { component: any; props?: any } | { jsx: JSX.Element }) {
  const element = "component" in input ? React.createElement(input.component, input.props) : input.jsx

  return <SentryErrorBoundary>{element}</SentryErrorBoundary>
}
