import React, { useState } from "react"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import { SizeSelect, Progress, OnOff, EstimatedCost } from "."
import { DangerousOperation, Controls, Alert, Tooltip } from ".."
import { ResizeServer, ResizeServerVariables, SERVER_FRAGMENT, ERROR_FRAGMENT, Size } from "../../graph"
import { SizeKind, formatSize, VultrProvider } from "../../models"

const RESIZE_SERVER = gql`
  mutation ResizeServer($input: ServerResizeInput!) {
    serverResize(input: $input) {
      node {
        ...Server
      }
      errors {
        ...Error
      }
    }
  }

  ${SERVER_FRAGMENT}
  ${ERROR_FRAGMENT}
`

export function ResizeForm({
  id,
  kind,
  size,
  pending,
  backupsEnabled,
  providerId,
  imageId,
  categoryId,
  currentSizeId,
  currentSizeKind
}: {
  id: string
  kind: SizeKind
  size: Size
  pending: boolean
  backupsEnabled: boolean
  imageId: string
  providerId: string
  categoryId?: string
  currentSizeId: string
  currentSizeKind: SizeKind
}) {
  const [permanent, setPermanent] = useState(false)
  const [newSize, setNewSize] = useState<Size | null>(null)
  const [progress, setProgress] = useState(pending)
  const [resizeServer] = useMutation<ResizeServer, ResizeServerVariables>(RESIZE_SERVER)

  const handleSubmit = async (event: React.FormEvent, close: () => any) => {
    event.preventDefault()

    await resizeServer({
      variables: { input: { id, permanent, sizeId: newSize?.id ?? "" } }
    })

    setProgress(true)
    close()
  }

  const handleResizeTrigger = (event: React.MouseEvent, open: () => any) => {
    event.preventDefault()

    if (newSize != null) open()
  }

  const tooltipContent = newSize == null ? "Select a size" : "Server is busy"

  return (
    <DangerousOperation
      warning={permanent ? "This is a permanent change and cannot be reversed!" : "Server will be resized..."}
      confirm={() => (
        <label htmlFor="resize-submit">
          <span className="btn-danger">Continue</span>
        </label>
      )}
    >
      {({ open, close }: Controls) => (
        <form onSubmit={event => handleSubmit(event, close)}>
          {progress && <Progress id={id} />}

          {permanent && (
            <div className="spacing-bottom-sm">
              <Alert type="error">
                Permanent resize is not reversible. The disk will be expanded and shrinking the hard disk is not
                possible without risking data loss.
              </Alert>
            </div>
          )}

          {providerId === VultrProvider && (
            <div className="spacing-bottom-sm">
              <Alert type="none">Vultr downsizing is not supported.</Alert>
            </div>
          )}

          <div className="row spacing-bottom-sm">
            <div className="col-8">
              <div className="spacing-bottom-sm">
                <SizeSelect
                  serverId={id}
                  providerId={providerId}
                  categoryId={categoryId}
                  permanent={permanent}
                  kind={kind}
                  selected={newSize?.id ?? null}
                  onChange={setNewSize}
                  currentSizeId={currentSizeId}
                  currentSizeKind={currentSizeKind}
                  format={!permanent && providerId !== VultrProvider ? formatNonPermanentDescription(size) : undefined}
                  whenEmpty={() => {
                    if (providerId === VultrProvider) {
                      return (
                        <Alert type="error">
                          There is insufficient free capacity on the Vultr's VM host node for a resize.
                        </Alert>
                      )
                    }
                  }}
                />
              </div>

              {newSize != null && (
                <div className="button-wrapper spacing-bottom-sm">
                  <EstimatedCost
                    size={newSize}
                    imageId={imageId}
                    providerId={providerId}
                    backupsEnabled={backupsEnabled}
                  />
                </div>
              )}
            </div>

            {providerId !== VultrProvider && (
              <div className="col-4">
                <label className="control-label">Permanent resize</label>

                <p className="text-muted">
                  <small>
                    Regular resize will increase CPU and RAM. Permanent resize will increase the disk size, making it a{" "}
                    <strong>permanent</strong> change that cannot be reversed.
                  </small>
                </p>

                <OnOff value={permanent} onChange={() => setPermanent(!permanent)} />
              </div>
            )}
          </div>

          <div className="button-wrapper spacing-top-sm">
            <Tooltip content={tooltipContent} disabled={newSize != null && !progress}>
              <button
                type="button"
                className="btn-primary"
                disabled={newSize == null || progress}
                onClick={event => handleResizeTrigger(event, open)}
              >
                Resize
              </button>
            </Tooltip>
          </div>

          <input id="resize-submit" className="hidden" type="submit" />
        </form>
      )}
    </DangerousOperation>
  )
}

const formatNonPermanentDescription = (currentSize: Size) => (size: Size) => (
  <>
    <div>{`${formatSize(size, "cost")}/mo (${formatSize(size, "costPerHour")}/ho)`}</div>
    <div className="chips">
      <span className="chip">
        <span className="chip-label">RAM</span>
        <span className="chip-value">{formatSize(size, "memory")}</span>
      </span>
      <span className="chip">
        <span className="chip-label">CPU</span>
        <span className="chip-value">{formatSize(size, "processor")}</span>
      </span>
      <span className="chip">
        <span className="chip-label">DISK</span>
        <span className="chip-value">{formatSize(currentSize, "storage")}</span>
      </span>
      <span className="chip">
        <span className="chip-label">TRAFFIC</span>
        <span className="chip-value">{formatSize(size, "bandwidth")}</span>
      </span>
    </div>
  </>
)
