export const PopOver = {
  initialize() {
    const elements = document.querySelectorAll(".js-popover")

    elements.forEach((element: Element) => {
      const content = element.querySelector(".popover-content")
      const trigger = element.querySelector(".js-popover-trigger")

      trigger?.addEventListener("click", () => {
        const isOpen = content?.classList.contains("open")
        content?.classList.toggle("open", !isOpen)
      })
    })

    document.addEventListener("click", closePopoverHandler)
  },

  finalize() {
    document.removeEventListener("click", closePopoverHandler)
  }
}

function closePopoverHandler(event: Event) {
  const elements = document.querySelectorAll(".js-popover")

  elements.forEach((element: Element) => {
    const content = element.querySelector(".popover-content")

    if (!element.contains(event.target as Node)) content?.classList.remove("open")
  })
}
