import React, { useState } from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { ListSelect, Loading, Segments, Tabs } from ".."
import { Size, SIZE_FRAGMENT, GetSizes, GetSizesVariables } from "../../graph"
import { formatSize, SizeKind, sizeKindsForProvider } from "../../models"

const GET_SIZES = gql`
  query GetSizes($providerId: ID, $serverId: ID, $regionId: ID, $categoryId: ID, $kind: String, $permanent: Boolean) {
    sizes(
      serverId: $serverId
      providerId: $providerId
      regionId: $regionId
      categoryId: $categoryId
      kind: $kind
      permanent: $permanent
    ) {
      ...Size
    }
  }

  ${SIZE_FRAGMENT}
`

export function SizeSelect({
  providerId,
  serverId,
  regionId,
  selected,
  currentSizeId,
  currentSizeKind,
  onChange,
  kind,
  onKindChange,
  categoryId,
  permanent = true,
  theme = "segments",
  className = "",
  format = defaultFormat,
  whenEmpty = () => undefined
}: {
  serverId?: string
  regionId?: string
  providerId: string
  selected: string | null
  onChange: (size: Size) => void
  categoryId?: string
  currentSizeId?: string
  currentSizeKind?: SizeKind
  permanent?: boolean
  kind?: SizeKind
  onKindChange?: (kind: SizeKind) => void
  theme?: "tabs" | "segments"
  className?: string
  format?(size: Size): JSX.Element
  whenEmpty?(): JSX.Element | undefined
}) {
  const kinds = sizeKindsForProvider(providerId)
  if (kind == null) kind = currentSizeKind || kinds[0]!

  const [type, setType] = useState(kind)
  const { data, loading, error, refetch } = useQuery<GetSizes, GetSizesVariables>(GET_SIZES, {
    variables: { providerId, serverId, regionId, categoryId, kind: type, permanent }
  })

  if (error) throw error

  return (
    <div className="sizes-select">
      <div className="spacing-bottom-sm">
        {!categoryId && theme === "tabs" && (
          <Tabs
            items={kinds}
            active={type}
            onChange={item => {
              setType(item)
              onKindChange?.(item)
              refetch({ providerId, serverId, regionId, kind: item })
            }}
          />
        )}
        {!categoryId && theme === "segments" && (
          <Segments
            items={kinds}
            active={type}
            onChange={item => {
              setType(item)
              onKindChange?.(item)
              refetch({ providerId, serverId, regionId, kind: item })
            }}
          />
        )}
      </div>

      {!data || loading ? (
        <Loading />
      ) : (
        <>
          <ListSelect
            className={className}
            items={data.sizes}
            format={format}
            selected={selected}
            onChange={(id: string) => {
              const item = data.sizes.find(size => size.id === id)
              if (item) {
                onChange(item)
                onKindChange?.(item.kind as SizeKind)
              }
            }}
            disabled={currentSizeId}
          />
          {data.sizes.length === 0 && whenEmpty()}
        </>
      )}
    </div>
  )
}

const defaultFormat = (size: Size) => (
  <>
    <div>{`${formatSize(size, "cost")}/mo (${formatSize(size, "costPerHour")}/ho)`}</div>
    <div className="chips">
      <span className="chip">
        <span className="chip-label">RAM</span>
        <span className="chip-value">{formatSize(size, "memory")}</span>
      </span>
      <span className="chip">
        <span className="chip-label">CPU</span>
        <span className="chip-value">{formatSize(size, "processor")}</span>
      </span>
      <span className="chip">
        <span className="chip-label">DISK</span>
        <span className="chip-value">{formatSize(size, "storage")}</span>
      </span>
      <span className="chip">
        <span className="chip-label">TRAFFIC</span>
        <span className="chip-value">{formatSize(size, "bandwidth")}</span>
      </span>
    </div>
  </>
)
